<router-outlet></router-outlet>

<div #contextMenuViewRef>
    <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        size="medium"
        color="#fff"
        type="line-scale"
        fullScreen="true"
    >
        <p style="color: white;">Loading...</p>
    </ngx-spinner>
    <div class="context-menu-origin" #contextMenuOrigin></div>
</div>

import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    count = 0;

    constructor(private spinner: NgxSpinnerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.spinner.show();

        this.count++;

        return next
            .handle(req)

            .pipe(
                tap(
                    (event) => true,

                    (error) => true
                ),
                finalize(() => {
                    this.count--;

                    if (this.count == 0) this.spinner.hide();
                })
            );
    }
}
